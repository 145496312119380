import React, { useState } from 'react';
import cc from 'classcat';
import Spinner from '@atlaskit/spinner';
import { captureException, setExtra } from '@sentry/core';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { Button } from '../../index';
import FormikField from '../FormikField';
import { Heading } from '../Wizard';
import { useStore } from '../../../hooks';
import { trackEvent } from '../../../lib/GA';
import FreeformHelper from '../../../lib/FreeformHelper';

import styles from './styles.module.scss';

interface ContactFormValues {
  fullName: string;
  phone: string;
  email: string;
  prefer: string;
  message: string;
  freeform_form_handle: string;
}

interface Props {
  section?: string;
  title?: string;
}

const SectionFormMapping = {
  'Sales Form': 'salesEnquireForm',
  'Retirement Village Location': 'retirementVilalge',
  'Retirement Village Location Unit': 'retirementVilalgeUnit',
  'Nursing Home Location': 'nursingHome',
  'Careers': 'careers',
};

const ContactForm = ({ section, title }: Props) => {
  const globals = useStore('globals');
  const phone = globals.phone || null;
  const initialValues: ContactFormValues = {
    fullName: '',
    phone: '',
    email: '',
    prefer: '',
    message: '',
    freeform_form_handle: ''
  };

  const getFormHandle = (section: string | undefined) => {
    let handle = section ? SectionFormMapping?.[section] || null : null;

    if (!handle) {
      if (globals.siteName.indexOf('Careers') !== -1) {
        handle = 'careersEnquireForm';
      } else if (globals.siteName.indexOf('Training') !== -1) {
        handle = 'trainingEnquireForm';
      } else {
        handle = 'enquireForm';
      }
    }

    return handle;
  }

  const formHandle = getFormHandle(section);
  const contactFreeForm = globals?.freeform?.find((form) => form.handle === formHandle);

  const [submitted, setSubmitted] = useState(false);
  const [errored, setErrored] = useState(false);

  async function handleSubmit(values: ContactFormValues, formikHelpers: FormikHelpers<ContactFormValues>) {
    const formData = new FormData();
    // istanbul ignore next
    Object.keys(values).forEach((key) => formData.append(key, values[key] == null ? '' : values[key]));
    formData.append('entryTitle', title || '');

    formData.append('handle', formHandle);
    formData.append('origin', globals.siteName || 'Amana Living');

    const response = await FreeformHelper.sendForm(formData, contactFreeForm);

    if (response.success) {
      setSubmitted(true);
      trackEvent('Contact Form', 'Successful Submission', document.title);
    } else {
      setErrored(true);
      setExtra('errors', response.errors);
      captureException('Contact Form submission failed');
    }

    formikHelpers.setSubmitting(false);
  }

  function renderConfirmation() {
    return (
      <div className={styles.message}>
        <Heading>Thank you for your enquiry!</Heading>
        <p>Thanks! Your enquiry has been received and someone from our team will be in touch with you shortly.</p>
      </div>
    );
  }

  function renderError() {
    return (
      <div className={styles.message}>
        <h3>Something went wrong</h3>
        <p>An error occured while trying to submit your enquiry.</p>
        <p>Please try again or call us below.</p>
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={false}
      validationSchema={ContactForm.validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit} className={cc({ [styles.contactForm]: true, [styles.submitting]: isSubmitting })}>
          {submitted && renderConfirmation()}
          {errored && renderError()}
          {!submitted && !errored && (
            <>
              <Heading>
                Fill in your details below or call us on <a href={`tel:${phone?.replace(/[^\d\+]/g, '')}`}>{phone}</a>
              </Heading>
              <div className="row mt-1">
                <div className="col-lg-6 mb-3 customInput">
                  <label htmlFor="fullName">Name</label>
                  <FormikField type="text" name="fullName" required placeholder="e.g. John" />
                </div>

                <div className="col-lg-6 mb-3 customInput">
                  <label htmlFor="phone">Phone</label>
                  <FormikField as="input" type="tel" name="phone" required placeholder="e.g. 0404 555 444" />
                </div>
              </div>

              <div className="row">
                <div className="col-12 mb-3 customInput">
                  <label htmlFor="email">Email</label>
                  <FormikField type="email" name="email" required placeholder="e.g. john@email.com" />
                </div>

                <div className="col-12 mb-3 customInput">
                  <p className="label">Preferred contact method</p>
                  <div className={styles.radios}>
                    <div className={styles.radio}>
                      <input
                        id="perferEmail"
                        name="prefer"
                        type="radio"
                        value="Email"
                        onChange={(e) => setFieldValue('prefer', e.currentTarget.value)}
                        required
                      />
                      <label htmlFor="perferEmail">Email</label>
                    </div>
                    <div className={styles.radio}>
                      <input
                        id="preferPhone"
                        name="prefer"
                        type="radio"
                        value="Phone"
                        onChange={(e) => setFieldValue('prefer', e.currentTarget.value)}
                        required
                      />
                      <label htmlFor="preferPhone">Phone</label>
                    </div>
                  </div>
                </div>

                <div className="col-12 customInput">
                  <label htmlFor="message">Your message</label>
                  <textarea
                    name="message"
                    onChange={(e) => setFieldValue('message', e.currentTarget.value)}
                    defaultValue={initialValues.message}
                    placeholder={title ? 'How we can help?' : 'What are you enquiring about?'}
                    maxLength={800}
                  />
                </div>


                <div className="col-12 mt-3">
                  {
                    contactFreeForm?.honeypot ? <input type={'hidden'} name={contactFreeForm.honeypot?.name || ''}
                        value={contactFreeForm.honeypot?.value || ''}/> : null
                  }

                  <Button as="submit" disabled={isSubmitting} type="orangeBorder" large>
                    Send email
                  </Button>
                </div>
              </div>
            </>
          )}

          <div className={cc({ [styles.spinner]: true, [styles.visible]: isSubmitting })}>
            <Spinner size="xlarge" isCompleting={!isSubmitting} />
          </div>
        </form>
      )}
    </Formik>
  );
};

ContactForm.validationSchema = Yup.object().shape({
  fullName: Yup.string().required(),
  phone: Yup.string().required(),
  email: Yup.string().email().required(),
  method: Yup.string(),
  message: Yup.string(),
});

export default ContactForm;
